import { gql } from '@apollo/client'

export type GraphPoint = {
  timestamp: number
  value: number
}

export const GRAPH_POINT_FIELDS = gql`
  fragment GraphPointFields on GraphPoint {
    timestamp
    value
  }
`
