import * as React from 'react'

import { Box, Stack, Typography } from '@mui/material'

import { AppContainer, CompositionGraph, PricesGraph } from '../components'
import { getPriceGraphData } from '../services'

import type { AssetPrices, Fund } from '../queries'

const graphHeight = 280

const CompositionGraphWrapper = ({ fund }: { fund: Fund }) => {
  const dummyParent = {
    id: 'portfolio',
    parent: null,
    size: null,
  }
  const data = fund.assets
    .map((asset, index) => ({
      id: asset,
      parent: 'portfolio',
      size: fund.percentages[index],
    }))
    .filter((item) => item.size !== 0)

  return (
    <Box sx={{ marginTop: 2, height: graphHeight }}>
      <CompositionGraph
        totalQuoteAmount={100}
        data={[dummyParent, ...data]}
      />
    </Box>
  )
}

type PricesGraphWrapperProps = {
  fund: Fund
  assetPrices?: AssetPrices[]
}

const PricesGraphWrapper = ({
  fund,
  assetPrices,
}: PricesGraphWrapperProps ) => {
  const graphData = getPriceGraphData(assetPrices || [], fund.symbol)

  return graphData.points.length > 0 ? (
    <Box sx={{ marginTop: 2, height: graphHeight }}>
      <PricesGraph
        currency='USD'
        data={graphData.points}
        ath={graphData.ath}
        atl={graphData.atl}
      />
    </Box>
  ) : (
    <Typography
      marginTop={2}
      textAlign='center'
    >
      No hay precios para mostrar
    </Typography>
  )
}

type FundDisplayProps = {
  fund: Fund
  graphType: 'COMPOSITION' | 'TOTAL_AMOUNT'
  assetPrices?: AssetPrices[]
  onClick?: () => void
  children?: React.ReactNode
}

export const FundDisplay = ({
  fund,
  graphType,
  assetPrices,
  onClick,
  children,
}: FundDisplayProps) => (
  <AppContainer
    sx={{ p: 2, ...(onClick && { cursor: 'pointer' }) }}
    onClick={onClick}
  >
    <Stack
      direction='row'
      justifyContent='space-between'
    >
      <Typography variant='h4'>
        {fund.name}
      </Typography>
      <Stack direction='row'>
        {children}
      </Stack>
    </Stack>
    {graphType === 'COMPOSITION' ? (
      <CompositionGraphWrapper fund={fund} />
    ) : (
      <PricesGraphWrapper
        fund={fund}
        assetPrices={assetPrices}
      />
    )}
  </AppContainer>
)
