import type { UserOperation, UserOperations } from '../queries'

export const withJsTimestamp = /* ugly typescript sorcery */
  <T extends { timestamp: number }>(userOperations: T[]): T[] =>
    userOperations.map(({ timestamp, ...rest }) => ({ timestamp: timestamp * 1000, ...rest }) as T)

export const asOperationsArray = (data?: UserOperations): UserOperation[] => {
  if (typeof data === 'undefined') {
    return []
  }

  const userOperations = Object
    .values(data)
    .filter((value) => value !== 'UserOperations')
    .flat() as UserOperation[]

  return withJsTimestamp(userOperations)
}

const hasSymbol = (op: UserOperation, symbols: string[]) =>
  ('symbol' in op && symbols.includes(op.symbol))
  || ('fromSymbol' in op && symbols.includes(op.fromSymbol))
  || ('toSymbol' in op && symbols.includes(op.toSymbol))

export const withSymbol = (operations: UserOperation[], symbol: string) =>
  operations.filter((op) => hasSymbol(op, [symbol]))

export const withSymbols = (operations: UserOperation[], symbols: string[]) =>
  operations.filter((op) => hasSymbol(op, symbols))

export const withType = (operations: UserOperation[], type: string) =>
  operations.filter((op) => op.__typename === type)

export const withTypes = (operations: UserOperation[], types: string[]) =>
  operations.filter((op) => types.includes(op.__typename))

export const withFund = (operations: UserOperation[], fundName: string) =>
  operations.filter((op) => 'fundName' in op && op.fundName === fundName)

export const getChangedAmount = (operation: UserOperation, symbol: string) => {
  switch (operation?.__typename) {
  case 'Deposit':
    return operation.changedAmount
  case 'InterestPayout':
    return operation.changedAmount
  case 'ReferralPayout':
    return operation.changedAmount
  case 'Swap':
    return operation.toSymbol === symbol ? operation.toChangedAmount : -operation.fromChangedAmount
  case 'Withdrawal':
    return -operation.changedAmount
  default:
    return 0
  }
}

export const getTotalAmount = (symbol: string, operations: UserOperation[]) => {
  const amount = operations.reduce((acc, op) => acc + getChangedAmount(op, symbol), 0)
  return amount > 1e-9 ? amount : 0
}

export const getTotalAmounts = (symbols: string[], operations: UserOperation[]) =>
  Object.fromEntries(
    symbols.map((symbol) => (
      [symbol, getTotalAmount(symbol, withSymbol(operations, symbol))]
    )),
  )

export const getTotalAmountLeft = (operations: { amountLeft: number }[]) => {
  const amount = operations.reduce((acc, op) => acc + op.amountLeft, 0)
  return amount > 1e-9 ? amount : 0
}
