import { gql } from '@apollo/client'

export type AssetUnspentInterests = {
  amount: number
  symbol: string
}

export const ASSET_UNSPENT_INTERESTS_FIELDS = gql`
  fragment AssetUnspentInterestsFields on AssetUnspentInterests {
    amount
    symbol
  }
`

export type AssetsUnspentInterestsVars = Record<string, never>

export type AssetsUnspentInterestsData = {
  assetsUnspentInterests: AssetUnspentInterests[]
}

export const ASSETS_UNSPENT_INTERESTS_QUERY = gql`
  ${ASSET_UNSPENT_INTERESTS_FIELDS}
  query AssetsUnspentInterests {
    assetsUnspentInterests {
      ...AssetUnspentInterestsFields
    }
  }
`
